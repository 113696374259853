import styles from "./index.module.scss";
import Navbar from "../navbar";
import Footer from "../footer";
import { useState } from "react";

const Index = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window && window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  if (typeof window !== "undefined")
    window.addEventListener("scroll", handleScroll);

  return (
    <div className={styles.mainContainer}>
      <div>
        <Navbar scrolled={scrolled} />
      </div>

      <main>{children}</main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Index;
