import styles from "./index.module.scss";
import { IoMdCheckmark } from "react-icons/io";
// import Image from "next/image";
import { aboutImage } from "../../../images";
import { Line } from "rc-progress";

const Index = () => {
  const values = [
    { percent: 98, name: "Client satisfaction" },
    { percent: 85, name: "Sustainability" },
    { percent: 77, name: "Livable environment" },
    { percent: 90, name: "Cost efficiency" },
    { percent: 87, name: "Project time frame" },
  ];
  return (
    <div className={styles.about} id="about">
      <div className={styles.container}>
        <div className={styles.title} data-aos="fade-right">
          <p>About us</p>
          <h1>about our company</h1>
          <hr />
        </div>

        <p className={styles.description} data-aos="fade-left">
          Malcan Architects and Town Planners is a South African company
          specializing in architectural and town planning work. Founded in 2016
          by Kopano Malcan Bokgobelo, a South African born Architect and Town
          Planner. The company&#39;s ultimate goal is to bring well planned,
          liveable and sustainable urban environments with beautiful, rigid and
          functional structural designs suitable for human habitation.
        </p>

        <div className={styles.grid}>
          <div className={styles.column} data-aos="fade-right">
            <div className={styles.imageWrapper}>
              <img
                src={aboutImage}
                className={styles.image}
                alt="about"
                height={350}
                width={600}
                placeholder="blur"
              />
            </div>
          </div>

          <div className={styles.column} data-aos="fade-left">
            <div className={styles.container}>
              <p className={styles.header}>Why choose us</p>
              <ul>
                {values.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className={styles.wrapper}>
                        <p>{item.name}</p>
                        <p>{item.percent + "%"}</p>
                      </div>
                      <Line
                        percent={item.percent}
                        strokeWidth={1}
                        strokeColor="#045de9"
                      />
                    </li>
                  );
                })}
              </ul>

              <div className={styles.wrapper2}>
                <div>
                  <div className={styles.iconWrapper}>
                    <IoMdCheckmark className={styles.icon} />
                  </div>
                  <p>Quality work</p>
                </div>
                <div className={styles.block}>
                  <div className={styles.iconWrapper}>
                    <IoMdCheckmark className={styles.icon} />
                  </div>
                  <p>Functionality</p>
                </div>
                <div>
                  <div className={styles.iconWrapper}>
                    <IoMdCheckmark className={styles.icon} />
                  </div>
                  <p>Aesthetic designs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
