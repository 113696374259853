import styles from "./index.module.scss";
import { useEffect, useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";

const Index = () => {
  const [current, setCurrent] = useState(0);
  const intervalRef = useRef(null);

  const setIntervalRef = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setCurrent((prev) => {
        if (prev + 1 > 1) return 0;
        else return prev + 1;
      });
    }, 10000);
  };

  useEffect(() => {
    setIntervalRef();

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className={styles.carousel}>
      <div
        className={
          current === 0
            ? [styles.slide, styles.current].join(" ")
            : styles.slide
        }
      >
        <div className={styles.overlay}>
          <div className={styles.container}>
            <h1>WELCOME TO MALCAN ARCHITECTS AND TOWN PLANNERS</h1>
            <p>
              We build our projects with your dreams and ideas in mind, creating
              a future that meets our clients&#39; goals
            </p>

            <HashLink to="#contacts" smooth>
              <div className={styles.btn}>
                <div className={styles.background}></div>
                <span>Get in touch</span>
              </div>
            </HashLink>
          </div>
        </div>
      </div>

      <div
        className={
          current === 1
            ? [styles.slide, styles.current].join(" ")
            : styles.slide
        }
      >
        <div className={styles.overlay}>
          <div className={styles.container}>
            <h1>WE PROVIDE WORLD-CLASS ARCHITECTURE AND TOWN PLAN SERVICES</h1>
            <p>
              We are passionate about our services and leverage our ability to
              pay great attention to detail in order to produce impeccable
              results. Continuous improvement is at the core of our business,
              enabling our clients to effectively meet their architectural and
              town planning requirements.
            </p>

            <HashLink to="#contacts" smooth>
              <div className={styles.btn}>
                <div className={styles.background}></div>
                <span>Get in touch</span>
              </div>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
