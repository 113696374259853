// import { useRouter } from "next/router";
import { About, Carousel, Contacts, Layout, Services } from "../component";
import styles from "../styles/Home.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Layout>
        <Carousel AOS={AOS} />

        <div className={styles.contactTeam}>
          <div className={styles.container}>
            <div>
              <h3>
                Would you like to connect with one of our leading experts?
              </h3>
              <p>
                Our professional team consists of Architects, Civil Engineers,
                Environmentalist, Town Planners, and Land Surveyors.
              </p>
            </div>

            <HashLink to="#contacts" smooth>
              <div className={styles.btn}>
                <div className={styles.background}></div>
                <span>Contact Member</span>
              </div>
            </HashLink>
          </div>
        </div>

        <Services AOS={AOS} />
        <About />

        <div className={styles.extra} data-aos="flip-down">
          <div className={styles.overlay}>
            <div className={styles.container}>
              <h2>
                We work closely with our clients in every project stage to best
                represent our clients&#39; unique style and character, therefore
                achieving optimal client satisfaction
              </h2>
            </div>
          </div>
        </div>

        <Contacts AOS={AOS} />
      </Layout>
    </>
  );
}
