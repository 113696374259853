// import Image from "next/image";
import {
  architectImage,
  carouselImage,
  extendImage,
  interiorImage,
  landscapePlanImage,
  planImage,
  renovateImage,
} from "../../../images";
import styles from "./index.module.scss";
import { AiOutlineFileDone } from "react-icons/ai";
import { ImFileText2 } from "react-icons/im";
import { GiModernCity } from "react-icons/gi";
import {
  MdOutlineAddLocationAlt,
  MdApproval,
  MdOutlineLandscape,
  MdOutlineRemoveModerator,
} from "react-icons/md";
import { RiLandscapeLine } from "react-icons/ri";

const architects = [
  {
    image: planImage,
    name: "Building Plans & Construction Plans",
    description:
      "Drawings showing building dimensions, materials and specifications, which are submitted to council for approval to build.",
  },
  {
    name: "Landscape Design",
    image: landscapePlanImage,
    description:
      "Arranging and modifying features in a landscape or urban area. An example is the planning of open spaces such as parks, gardens, and sidewalks.",
  },
  {
    name: "Architectural Design",
    image: architectImage,
    description:
      "We cover residential house design, commercial and industrial building designs.",
  },
  {
    name: "Interior Design",
    image: interiorImage,
    description:
      "Designing the layout of the internal space of a building, such as kitchen and bathroom designs.",
  },
  {
    name: "Extension",
    image: extendImage,
    description:
      "A new room or building which is added to an existing building.",
  },
  {
    name: "Renovation",
    image: renovateImage,
    description: "Restoring old buildings to an improved state of repair.",
  },
];

const townPlans = [
  {
    name: "Rezoning",
    icon: <MdOutlineAddLocationAlt className={styles.icon} />,
    description:
      "A change in Primary Land Use Rights. This covers changing the land use from Residential to Business use.",
  },
  {
    name: "Special Consent & Written Consent",
    icon: <AiOutlineFileDone className={styles.icon} />,
    description:
      "Addition of Secondary Land Use Rights, such as Tavern, Guest house, Child Care Facility, only by consent from council.",
  },
  {
    name: "Site Development Plan (SDP)",
    icon: <ImFileText2 className={styles.icon} />,
    description:
      "A plan showing the layout, position and extent of buildings in the proposed development of a site.",
  },
  {
    name: "Building line Relaxation",
    icon: <MdApproval className={styles.icon} />,
    description:
      "Permission granted by council for encroachment on a building restriction area.",
  },
  {
    name: "Subdivision",
    icon: <MdOutlineLandscape className={styles.icon} />,
    description:
      "Dividing a stand or portion of land into two or more portions.",
  },
  {
    name: "Consolidation",
    icon: <RiLandscapeLine className={styles.icon} />,
    description: "The combining or two stands or portions of land.",
  },
  {
    name: "Township Establishment",
    icon: <GiModernCity className={styles.icon} />,
    description:
      "Feasibility studies are made to check land suitability to establish a township, in consideration of traffic impact as well as environmental studies such as geotechnical aspects.",
  },
  {
    name: "Removal of Restrictive Conditions (ROR)",
    icon: <MdOutlineRemoveModerator className={styles.icon} />,
    description:
      "When conditions registered in the title deed don’t allow a particular development, such conditions can be removed through ROR.",
  },
];

const Index = () => {
  return (
    <div className={styles.services} id="services">
      <div className={styles.container}>
        <div className={styles.title} data-aos="fade-left">
          <p>Architectural</p>
          <h1>Services we offer</h1>
          <hr />
        </div>

        <div className={styles.row}>
          {architects.map((item, index) => {
            return (
              <div
                className={styles.card}
                key={index}
                data-aos="fade-left"
                data-aos-delay={`${3 + index}00`}
              >
                <div className={styles.imageWrapper}>
                  <img
                    placeholder="blur"
                    src={item.image}
                    className={styles.image}
                    alt="service"
                    layout="fill"
                  />
                </div>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.title} data-aos="fade-right">
              <p>Town Planning</p>
              <h1>Services we offer</h1>
              <hr />
            </div>

            <div className={styles.info} data-aos="fade-right">
              <p>
                Our offices are based in Gauteng, servicing all nine provinces
                of South Africa and international regions.
              </p>
            </div>
          </div>

          <div className={styles.row}>
            {townPlans.map((item, index) => {
              return (
                <div
                  className={styles.card2}
                  key={index}
                  data-aos="fade-right"
                  data-aos-delay={`${3 + index}00`}
                >
                  <div className={styles.content}>
                    <div className={styles.iconWrapper}>{item.icon}</div>
                    <h4>{item.name}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.grid}>
          <h3>
            Your <span>happiness</span> is under construction. We are designing
            dreams.
          </h3>

          <div className={styles.column}>
            <div className={styles.imageWrapper}>
              <img
                src={carouselImage}
                className={styles.image}
                placeholder="blur"
                alt="service"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
