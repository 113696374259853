import styles from "./index.module.scss";
import { FaPhone, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { MdLocationPin, MdMail } from "react-icons/md";
import { BsPinterest } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
// import Image from "next/image";
import { contactImage2 } from "../../../images";
import { SOCIAL_MEDIA_URLS } from "../../../helpers/constants";

const Index = () => {
  return (
    <div className={styles.contacts} id="contacts">
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.column} data-aos="fade-right">
            <img
              placeholder="blur"
              alt="contact"
              src={contactImage2}
              className={styles.image}
            />
          </div>

          <div className={styles.column}>
            <div className={styles.container}>
              <div className={styles.title} data-aos="fade-left">
                <p>Let&#39;s collaborate!</p>
                <h1>Ways To Contact Us</h1>
                <hr />
              </div>

              <p data-aos="fade-left" data-aos-delay="300">
                We are open from Monday to Saturday | 08:00 to 17:00
              </p>

              <div
                className={styles.socialMedia}
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <a
                  target="_blank"
                  href={SOCIAL_MEDIA_URLS.FACEBOOK}
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className={styles.icon} />
                </a>
                <a
                  target="_blank"
                  href={SOCIAL_MEDIA_URLS.TWITTER}
                  rel="noopener noreferrer"
                >
                  <FaTwitter className={styles.icon} />
                </a>
                <a
                  target="_blank"
                  href={SOCIAL_MEDIA_URLS.LINKEDIN}
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn className={styles.icon} />
                </a>
                <a
                  target="_blank"
                  href={SOCIAL_MEDIA_URLS.INSTAGRAM}
                  rel="noopener noreferrer"
                >
                  <AiFillInstagram className={styles.icon} />
                </a>
                <a
                  target="_blank"
                  href={SOCIAL_MEDIA_URLS.PINTEREST}
                  rel="noopener noreferrer"
                >
                  <BsPinterest className={styles.icon} />
                </a>
              </div>

              <h3 data-aos="fade-left" data-aos-delay="500">
                JOHANNESBURG, MIDRAND
              </h3>

              <div
                className={styles.row}
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <MdLocationPin className={styles.icon} />
                118 Summerset village, Summerset Road, Noordwyk Midrand
              </div>

              <div
                className={styles.row}
                data-aos="fade-left"
                data-aos-delay="700"
              >
                <FaPhone className={styles.icon} />
                <a
                  target="_blank"
                  href="tel:+27799998768"
                  rel="noopener noreferrer"
                >
                  <span>27 &#40;0&#41; 799 998 768</span>
                </a>
              </div>

              <div
                className={styles.row}
                data-aos="fade-left"
                data-aos-delay="800"
              >
                <MdMail className={styles.icon} />
                <a
                  target="_blank"
                  href="mailto:info@malcanarchitects.co.za"
                  rel="noopener noreferrer"
                  className={styles.mail}
                >
                  <span>info@malcanarchitects.co.za</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
