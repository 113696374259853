import styles from "./index.module.scss";
import { MdMail, MdMenu, MdClose } from "react-icons/md";
import { FaPhone, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { BsPinterest } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { SOCIAL_MEDIA_URLS } from "../../helpers/constants";

const Index = ({ scrolled }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={scrolled ? styles.header2 : styles.header}>
      {!scrolled && (
        <div className={styles.contacts}>
          <div className={styles.container}>
            <div className={styles.links}>
              <a
                target="_blank"
                href="mailto:info@malcanarchitects.co.za"
                rel="noopener noreferrer"
                className={styles.mail}
              >
                <MdMail className={styles.icon} />
                <span>info@malcanarchitects.co.za</span>
              </a>

              <a
                target="_blank"
                href="tel:+27799998768"
                rel="noopener noreferrer"
              >
                <FaPhone className={styles.icon} />
                <span>27 &#40;0&#41; 799 998 768</span>
              </a>
            </div>

            <div className={styles.links}>
              <a
                target="_blank"
                href={SOCIAL_MEDIA_URLS.FACEBOOK}
                rel="noopener noreferrer"
              >
                <FaFacebookF className={styles.icon} />
              </a>
              <a
                target="_blank"
                href={SOCIAL_MEDIA_URLS.TWITTER}
                rel="noopener noreferrer"
              >
                <FaTwitter className={styles.icon} />
              </a>
              <a
                target="_blank"
                href={SOCIAL_MEDIA_URLS.LINKEDIN}
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className={styles.icon} />
              </a>
              <a
                target="_blank"
                href={SOCIAL_MEDIA_URLS.INSTAGRAM}
                rel="noopener noreferrer"
              >
                <AiFillInstagram className={styles.icon} />
              </a>
              <a
                target="_blank"
                href={SOCIAL_MEDIA_URLS.PINTEREST}
                rel="noopener noreferrer"
              >
                <BsPinterest className={styles.icon} />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className={styles.navbar}>
        <div className={styles.container}>
          <Link to="/">
            <h2>MA&#38;TP</h2>
          </Link>

          <ul className={styles.menu}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <HashLink to="#services" smooth>
                our services
              </HashLink>
            </li>
            <li>
              <HashLink to="#about" smooth>
                about us
              </HashLink>
            </li>
            <li>
              <HashLink to="#contacts" smooth>
                contact us
              </HashLink>
            </li>
          </ul>

          {showMenu ? (
            <MdClose
              className={styles.menuBtn}
              onClick={() => setShowMenu((prev) => !prev)}
            />
          ) : (
            <MdMenu
              className={styles.menuBtn}
              onClick={() => setShowMenu((prev) => !prev)}
            />
          )}
        </div>

        <ul
          className={styles.mobileMenu}
          style={{ transform: showMenu ? "scale(1)" : "scale(0)" }}
        >
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <HashLink to="#services" smooth>
              our services
            </HashLink>
          </li>
          <li>
            <HashLink to="#about" smooth>
              about us
            </HashLink>
          </li>
          <li>
            <HashLink to="#contacts" smooth>
              contact us
            </HashLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Index;
