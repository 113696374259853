import styles from "./index.module.scss";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { BsPinterest } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { SOCIAL_MEDIA_URLS } from "../../helpers/constants";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const Index = () => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {/* <h3>Malcan Architects and Town Planners</h3> */}

        <ul className={styles.links}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>.</li>
          <li>
            <HashLink to="#services" smooth>
              our services
            </HashLink>
          </li>
          <li>.</li>
          <li>
            <HashLink to="#about" smooth>
              about us
            </HashLink>
          </li>
          <li>.</li>
          <li>
            <HashLink to="#contacts" smooth>
              contact us
            </HashLink>
          </li>
        </ul>

        <div className={styles.socialMedia}>
          <a
            target="_blank"
            href={SOCIAL_MEDIA_URLS.FACEBOOK}
            rel="noopener noreferrer"
          >
            <FaFacebookF className={styles.icon} />
          </a>
          <a
            target="_blank"
            href={SOCIAL_MEDIA_URLS.TWITTER}
            rel="noopener noreferrer"
          >
            <FaTwitter className={styles.icon} />
          </a>
          <a
            target="_blank"
            href={SOCIAL_MEDIA_URLS.LINKEDIN}
            rel="noopener noreferrer"
          >
            <FaLinkedinIn className={styles.icon} />
          </a>
          <a
            target="_blank"
            href={SOCIAL_MEDIA_URLS.INSTAGRAM}
            rel="noopener noreferrer"
          >
            <AiFillInstagram className={styles.icon} />
          </a>
          <a
            target="_blank"
            href={SOCIAL_MEDIA_URLS.PINTEREST}
            rel="noopener noreferrer"
          >
            <BsPinterest className={styles.icon} />
          </a>
        </div>

        <p>© Copyright {year} malcanarchitects. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Index;
